import axios from "axios";

let is_appLocal = localStorage.getItem('is_app')

export const validNumber = (value, setValue) => {
    if  (/^\d+$/.test(value)) {
        setValue(value)
      } else {
        setValue('')
    }
}

export const validEmail = (value) => {
  return  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value);
}

export const validatePassword = (str) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  return regex.test(str);
};

export const handleValidURL = (str) => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)?\.[a-zA-Z]{2,6}(\/[^\s]*)?$/;
  return regex.test(str);
}

export const fetchGetData = async (url) => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`);
      return data.data
    } catch (error) {
      console.error('Error:', error);
      return null;
    } 
}

export const fetchRequest = async ( method, url, data, catchFunc=null, finallyFunc=null) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}${url}`,
        method: method,
        data: data,
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      if (catchFunc) {
          catchFunc(error);
      }
      return error.response.data;
    } finally {
      if (finallyFunc) {
        finallyFunc();
      }
    }
}