import './PageMeta.css';
import { Helmet } from 'react-helmet';
import { memo } from 'react';

function PageMeta({ title, description, keywords, }) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default memo(PageMeta);