import './ContactView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import FormBlock from '../../components/FormBlock/FormBlock';

function ContactView() {

    return (
        <div className='contact-view-wrap'>
            <PageMeta {...pageMeta['ContactView']} />
            <div className='contact-view container'>
                <div className='contact-view__main-wrap'>
                    <div className='contact-view__main-text-wrap'>
                        <a 
                            className='contact-view__main-text' 
                            href='mailto:hello@we-nocode.com'
                        >
                            hello@we-nocode.com
                        </a>
                    </div>
                </div>
            </div>
            <FormBlock />
        </div>
    );
}

export default ContactView;