import './FormBlock.css';
import { memo, useState } from 'react';
import formImg from '../../assets/formImg.png';
import { OutlinedInput } from '@mui/material';
import { validEmail } from '../../helpers/Utils';
import letsTalkImg from '../../assets/letsTalkImg.png';
import ShowMessage from '../ShowMessage/ShowMessage';

function FormBlock() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showMessageObj, setShowMessageObj] = useState({ open: false, status: '', message: '' }); 

    const handleSend = () => {
        setEmailError(!validEmail(email) ? 'Incorrect email' : ''); 

        if (!validEmail(email)) {
            return;
        }

        if (fullName?.length && email?.length)  {
            fetch(process.env.REACT_APP_SLACK_WEBHOOK_URL, {
                method: 'POST',
                body: JSON.stringify({text: `Full Name: ${fullName}\nEmail: ${email}\nDescription: ${description}`})
            })
            .then(res => {
                if (res.ok) {
                    console.log('Success sending message to Slack');
                    setEmail('');
                    setFullName('');
                    setDescription('');
                    setShowMessageObj({ 
                        open: true, 
                        status: 'success', 
                        message: 'Your message sent' 
                    });
                } else {
                    setShowMessageObj({ 
                        open: true, 
                        status: 'error', 
                        message: 'Error sending message. Please try again later.' 
                    });
                    console.error('Error sending message to Slack');
                }
            })
            .catch(error => {
                setShowMessageObj({ 
                    open: true, 
                    status: 'error', 
                    message: 'Fetch error. Please try again.' 
                });
                console.error('Fetch error:', error);
            });
        }
    };

    return (
        <div className='form-block-wrap'>
            <div className='form-block'>
                <div className='form-block__img-wrap'>
                    <img 
                        className='form-block__img' 
                        src={formImg} 
                        alt="man" 
                    />
                    <img 
                        className='form-block__img-talk' 
                        src={letsTalkImg} 
                        alt="letsTalk" 
                    />
                    <h2 className='form-block__text-talk'>Let's Talk</h2>
                </div>
                <div className='form-block__form-wrap'>
                    <h3 className='form-block__form-title'>CREATE A PROJECT</h3>
                    <div className='form-block__form-input-wrap'>
                        <label className='form-block__form-input-label'>Full name</label>
                        <OutlinedInput
                            className='form-block__form-input'
                            placeholder="Your full name" 
                            onChange={(e) => setFullName(e.target.value)}
                            value={fullName}
                        />
                    </div>
                    <div className='form-block__form-input-wrap'>
                        <label className='form-block__form-input-label'>Email</label>
                        <OutlinedInput
                            className={`form-block__form-input ${emailError ? 'error' : ''}`}
                            placeholder="Email" 
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <label className='form-block__error-text'>{emailError}</label>
                    </div>
                    <div className='form-block__form-input-wrap'>
                        <label className='form-block__form-input-label'>Description</label>
                        <OutlinedInput
                            className='form-block__form-input'
                            placeholder="Description" 
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            multiline={true}
                            rows={5}
                        />
                    </div>
                    <button
                        className='form-block__form-btn'
                        onClick={() => handleSend()}
                    >
                        Send
                    </button>
                </div>
                <div className='form-block__text-wrap'>
                    <div className='form-block__text'>Let’s talk</div>
                </div>
            </div>
            <ShowMessage showMessageObj={showMessageObj} />
        </div>
    );
}

export default memo(FormBlock);