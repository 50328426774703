export let SOFTWARE = 'software';
export let AINTELLIGENCE = 'artificial_intelligence';
export let BUSINESS = 'business_services';
export let CYBERSECURITY = 'cybersecurity';
export let SERVICES = 'data_services';
export let DESIGN = 'design';
export let WRITEAPI = 'write_api';
export let ARTMASHER = 'art-masher';
export let VYROBY = 'vyroby';
export let PROCOACH = 'pro_coach';
export let VULCAN = 'vulcan'
export let DEVELOPMENT = 'development'
export let GENERATEIMG = 'generating_images'
export let AIVSAI = 'ai_vs_ai'
export let GENERATEVIDEO = 'generating_video'