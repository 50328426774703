import './ShowMessage.css';
import { useState, useEffect } from 'react';
import notificationPopapImg from '../../assets/notificationPopapImg.png';

function ShowMessage({ showMessageObj }) { 
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (showMessageObj?.open) {
            setOpen(true);
        }
    }, [showMessageObj]);

    const handleClose = () => {
        setOpen(false);
    };

    if (!open) return null;

    return (
        <div className='show-message-overlay'>
            <div className='show-message_notification--wrap'>
                <h2>{showMessageObj?.message}</h2>
                <img className='show-message-img' src={notificationPopapImg} alt="notificationPopapImg" />
                <div className='show-message-notification-bottom-wrap'>
                    <p className='show-message-text'>We will contact you soon</p>
                    <button className='show-message_btn-close' onClick={handleClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default ShowMessage;
