import { useDispatch } from 'react-redux';
import './AccordionBlock.css';
import { memo, useState } from 'react';
import { setServicesBlockScroll } from '../../store/homeSlice';
import { NavLink } from 'react-router-dom';

function AccordionBlock({ title, content, idScroll}) {
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className={`accordion-block-wrap ${isActive ? 'accordion-block-wrap__active' : ''}`}>
            <button className={`accordion-block ${isActive ? 'accordion-block__active' : ''}`} onClick={() => setIsActive(!isActive)}>
                {title}
                <span className="symbol">{isActive ? '\u2212' : '\u002B'}</span>
            </button>
            <div className="accordion-block__panel" style={{ maxHeight: isActive ? '250px' : '0px' }}>
                <p>{content}</p>
                <NavLink 
                    className='accordion-block__read-more'
                    onClick={() => dispatch(setServicesBlockScroll(idScroll))}
                    to={'/services'}
                >
                    Read more
                </NavLink>
            </div>
        </div>
    );
}

export default memo(AccordionBlock);