import './SteckPopover.css';
import { memo, useState } from 'react';
import { Popover } from '@mui/material';

function SteckPopover({image, text}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='steck-popover'>
            <img 
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                className='steck-popover__btn-img' 
                src={image?.length ? image : ''} 
                alt='img'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                classes={{
                    root: 'steck-popover__popover-wrap',
                }}
            >
                <p className='steck-popover__popover-text'>{text}</p>
            </Popover>
        </div>
    );
}

export default memo(SteckPopover);