import './ServicesView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import FormBlock from '../../components/FormBlock/FormBlock';
import services1 from '../../assets/services1.png';
import services2 from '../../assets/services2.png';
import services3 from '../../assets/services3.png';
import services4 from '../../assets/services4.png';
import services5 from '../../assets/services5.png';
import services6 from '../../assets/services6.png';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setServicesBlockScroll } from '../../store/homeSlice';
import { AINTELLIGENCE, BUSINESS, CYBERSECURITY, DESIGN, SERVICES, SOFTWARE } from '../../helpers/Config';

function ServicesView() {
    const servicesBlockScroll = useSelector(state => state.homeSlice.servicesBlockScroll);
    const dispatch = useDispatch();

    useEffect(() => {
        if (servicesBlockScroll?.length) {
            setTimeout(() => {
                document.getElementById(servicesBlockScroll)?.scrollIntoView({ behavior: 'smooth' });
            }, 100)
        }

        return () => {
            dispatch(setServicesBlockScroll(''));
        }
    }, []);

    const data = [
        {
            idScroll: SOFTWARE,
            image: services1,
            title: 'Software',
            text: 'Unlock the boundless potential of software, your ultimate versatile tool. Empowering you to conquer a myriad of challenges, our solutions are engineered to be efficient, scalable, and adaptable. Committed to sustainability, we craft software that not only solves problems but also embraces responsibility, shaping a better future.'
        },
        {
            idScroll: AINTELLIGENCE,
            image: services2,
            title: 'Artificial intelligence',
            text: 'Embrace the future of technology with WeNoCode. Our expertise in Artificial Intelligence (AI) is revolutionizing the IT landscape, propelling businesses into a realm of unprecedented possibilities. From automating tasks and extracting valuable insights from data to enhancing customer experiences through intelligent chatbots and personalized content delivery, our AI solutions are crafted to meet the unique needs of diverse industries. With WeNoCode, your business gains a competitive edge, harnessing the limitless potential of AI for streamlined processes, informed decision-making, and unparalleled innovation. Join us in shaping the digital future as we navigate the transformative power of Artificial Intelligence together.'
        },
        {
            idScroll: BUSINESS,
            image: services3,
            title: 'Business services',
            text: 'Transform your business with WeNoCode\'s AI-powered services. We specialize in delivering innovative solutions that streamline operations, drive efficiency, and unlock untapped potential. From intelligent automation to predictive analytics, our services are designed to empower businesses across industries. Whether you aim for enhanced customer engagement or data-driven insights, WeNoCode is your trusted partner at the intersection of technology and business. Elevate your operations with our cutting-edge AI services for sustained success.'
        },
        {
            idScroll: CYBERSECURITY,
            image: services4,
            title: 'Cybersecurity',
            text: 'Guard your digital fortress with our comprehensive cybersecurity services. Our expert team employs cutting-edge technologies to shield your organization from evolving cyber threats. From robust network defenses to proactive threat detection, we offer a tailored suite of services ensuring the utmost protection for your valuable assets. Stay ahead in the digital landscape with our cybersecurity solutions - your first line of defense in an interconnected world.'
        },
        {
            idScroll: SERVICES,
            image: services5,
            title: 'Data services',
            text: 'Elevate your business through our advanced data services. Unlock the full potential of your data with our comprehensive suite of solutions, ranging from data analytics and warehousing to secure data management. Our expert team ensures seamless integration, providing actionable insights that drive informed decision-making. Trust us to optimize, organize, and protect your data, empowering your business to thrive in the data-driven era. Explore the possibilities with our tailored data services, where precision meets performance.'
        },
        {
            idScroll: DESIGN,
            image: services6,
            title: 'UI/UX Design',
            text: 'Immerse your audience in unparalleled digital experiences  with our visionary UI/UX design services. We craft interfaces that seamlessly blend aesthetics with functionality, ensuring a delightful user journey. From intuitive navigation to visually stunning designs, our expert team leverages the latest trends and technologies to elevate your brand. Transform your ideas into captivating digital realities that resonate with your audience. Unleash the power of exceptional design – where user satisfaction meets impactful aesthetics. Elevate your brand with our UI/UX expertise, where innovation meets user-centric brilliance.'
        }
    ]

    return (
        <div className='services-view-wrap'>
            <PageMeta {...pageMeta['ServicesView']} />
            <div className='services-view container'>
                <div className='services-view__main-wrap'>
                    <div className='services-view__main-text-wrap'>
                        <h2 className='services-view__main-text'>Services</h2>
                        <p className='services-view__main-sub-text'>Transform your business with tailored IT solutions. From strategy to implementation, we've got you covered.  Stay ahead in the digital realm with our expert team</p>
                    </div>
                </div>
                {
                    !!data?.length && data.map((el, index) => (
                        <div 
                            className='services-view__text--wrap' 
                            id={el.idScroll}
                            key={index}
                        >
                            <img 
                                className='services-view__img'
                                src={el.image}
                                alt='img'
                            />
                            <div className='services-view__text-wrap'>
                                <div className='services-view__text-title'>{el.title}</div>
                                <div className='services-view__text'>{el.text}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <FormBlock />
        </div>
    );
}

export default ServicesView;