import './ShowcaseView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import FormBlock from '../../components/FormBlock/FormBlock';
import caseImg1 from '../../assets/caseImg1.png';
import vulcanDesImg from '../../assets/vulcanDesImg.png';
import vyrobyDesImg from '../../assets/vyrobyDesImg.png';
import proCoachDesImg from '../../assets/proCoachDesImg.png';
import { NavLink } from 'react-router-dom';
import { ARTMASHER, PROCOACH, VYROBY, VULCAN } from '../../helpers/Config';

function ShowcaseView() {

    const data = [
        {
            image: caseImg1,
            url: `/showcase/${ARTMASHER}`,
            title: 'Art Masher',
        },
        {
            image: vyrobyDesImg,
            url: `/showcase/${VYROBY}`,
            title: 'Vyroby',
        },
    ];
    const data2 = [  
        {
            image: proCoachDesImg,
            url: `/showcase/${PROCOACH}`,
            title: 'Pro Coach',
        },
        {
            image: vulcanDesImg,
            url: `/showcase/${VULCAN}`,
            title: 'Vulcan',
        }
    ];

    return (
        <div className='showcase-view-wrap'>
            <PageMeta {...pageMeta['ShowcaseView']} />
            <div className='showcase-view container'>
                <div className='showcase-view__main-wrap'>
                    <div className='showcase-view__main-text-wrap'>
                        <h2 className='showcase-view__main-text'>Showcase</h2>
                        <p className='showcase-view__main-sub-text'>Discover our showcase for success stories and innovative solutions. See how our expertise drives impactful results.</p>
                    </div>
                </div>
                <div className='showcase-view__img---wrap'>
                    <div className='showcase-view__img--wrap'>
                        {
                            !!data?.length && data.map((el, index) => (
                                <NavLink 
                                    className={`showcase-view__img-wrap ${`float${(index % 4) + 1}`} ${index % 2 ? ' showcase-view__img-wrap--tranform' : ''}`}
                                    to={el.url} 
                                    key={index}
                                >
                                    <img 
                                        className='showcase-view__img' 
                                        src={el.image} 
                                        alt="caseImg"
                                    />
                                    <h4 className='showcase-view__img-text'>{el.title}</h4>
                                </NavLink>
                            ))
                        }
                    </div>
                    <div className='showcase-view__img--wrap showcase-view__img--wrap-padding'>
                        {
                            !!data2?.length && data2.map((el, index) => (
                                <NavLink 
                                    className={`showcase-view__img-wrap ${`float${(index % 4) + 1}`} ${index % 2 ? ' showcase-view__img-wrap--tranform' : ''}`}
                                    to={el.url} 
                                    key={index}
                                >
                                    <img 
                                        className='showcase-view__img' 
                                        src={el.image} 
                                        alt="caseImg"
                                    />
                                    <h4 className='showcase-view__img-text'>{el.title}</h4>
                                </NavLink>
                            ))
                        }
                    </div>
                </div>
            </div>
            <FormBlock />
        </div>
    );
}

export default ShowcaseView;