import './NotFoundView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';

function NotFoundView() {

    return (
        <div className='not-found-wrap'>
            <PageMeta {...pageMeta['NotFoundView']} />

            <div className="not-found container">
                <p className="not-found__number">404</p>
                <p className='not-found__text'>Page not found</p>
            </div>
        </div>
    );
}

export default NotFoundView;