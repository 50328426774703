import './Footer.css';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import logoImg from '../../assets/logoImg.svg';
import facebookImg from '../../assets/facebookImg.svg';
import instagramImg from '../../assets/instagramImg.svg';
import linkedinImg from '../../assets/linkedinImg.svg';
import youTubeImg from '../../assets/youTubeImg.svg';

function Footer() {

    return (
        <footer className='footer-wrap'>
            <div className="footer container">
                <NavLink
                    className='footer__logo-wrap'
                    to='/'
                >
                    <img 
                        className='footer__logo'
                        src={logoImg} 
                        alt="logo" 
                    />
                </NavLink>
                <div className='footer__menu-wrap'>
                    <div className='footer__title'>Menu:</div>
                    <ul className='footer__menu'>
                        <li className='footer__menu-item'>
                            <NavLink 
                                className='footer__text footer__menu-link'
                                to='/'
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className='footer__menu-item'>
                            <NavLink 
                                className='footer__text footer__menu-link'
                                to='/services'
                            >
                                Services
                            </NavLink>
                        </li>
                        <li className='footer__menu-item'>
                            <NavLink 
                                className='footer__text footer__menu-link'
                                to='/showcase'
                            >
                                Showcase
                            </NavLink>
                        </li>
                        <li className='footer__menu-item'>
                            <NavLink 
                                className='footer__text footer__menu-link'
                                to='/blogs'
                            >
                                Blog
                            </NavLink>
                        </li>
                        <li className='footer__menu-item'>
                            <NavLink 
                                className='footer__text footer__menu-link'
                                to='/contact'
                            >
                                Contact us
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className='footer__link-wrap'>
                    <div className='footer__link-email-wrap'>
                        <div className='footer__title'>Email us:</div>
                        <a 
                            className='footer__text footer__text-email' 
                            href='mailto:hello@we-nocode.com'
                        >
                            hello@we-nocode.com
                        </a>
                    </div>
                    <div className='footer__link-follow-wrap'>
                        <div className='footer__title'>Email us:</div>
                        <div className='footer__link-follow-links-wrap'>
                            <ul className='footer__link-follow-links'>
                                <li className='footer__link-follow-link-item'>
                                    <a 
                                        className='footer__link-follow-link'
                                        href='https://www.facebook.com/WeNoCode'
                                        target='_blank'
                                    >
                                        <img 
                                            className='footer__link-follow-link-img'
                                            src={facebookImg} 
                                            alt="facebook" 
                                        />
                                    </a>
                                </li>
                                <li className='footer__link-follow-link-item'>
                                    <a 
                                        className='footer__link-follow-link'
                                        href='https://www.instagram.com/wenocodeinc'
                                        target='_blank'
                                    >
                                        <img 
                                            className='footer__link-follow-link-img'
                                            src={instagramImg} 
                                            alt="instagram" 
                                        />
                                    </a>
                                </li>
                                <li className='footer__link-follow-link-item'>
                                    <a 
                                        className='footer__link-follow-link'
                                        href='https://www.linkedin.com/company/wenocode-inc'
                                        target='_blank'
                                    >
                                        <img 
                                            className='footer__link-follow-link-img'
                                            src={linkedinImg} 
                                            alt="linkedin" 
                                        />
                                    </a>
                                </li>
                                <li className='footer__link-follow-link-item'>
                                    <a 
                                        className='footer__link-follow-link'
                                        href='https://www.youtube.com/@wenocode'
                                        target='_blank'
                                    >
                                        <img 
                                            className='footer__link-follow-link-img'
                                            src={youTubeImg} 
                                            alt="youTube" 
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='footer__address-wrap'>
                    <div className='footer__title'>Visit us:</div>
                    <div className='footer__text footer__address'>Jõe tn 4C, Tallinn 10151, Harju County Estonia</div>
                </div>
            </div>
        </footer>
    );
}

export default memo(Footer);