import './BlogBottom.css';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import formImg from '../../assets/formImg.png';
import facebookImg from '../../assets/facebookImg.svg';
import twitterImg from '../../assets/twitterImg.svg';
import linkedinImg from '../../assets/linkedinImg.svg';
import blogImg5 from '../../assets/blogImg5.png';
import blogImg3 from '../../assets/blogImg3.png';
import blogImg4 from '../../assets/blogImg4.png';
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavLink } from 'react-router-dom';

function BlogBottom() {
    const windowInnerWidth = useSelector(state => state.homeSlice.windowInnerWidth);

    const dataSwiper = [
        {
            img: blogImg5,
            title: 'AI vs. AI: The Battle for Supremacy',
            text: 'In the rapidly evolving world of artificial intelligence, a new kind of competition is emerging—one where AI systems are pitted against each other in a quest for dominance. This “AI vs. AI” showdown is not just about which algorithm is superior, but about how these intelligent systems can collaborate, compete, and redefine the boundaries of innovation.',
            link: '/blog/ai_vs_ai'
        },
        {
            img: blogImg3,
            title: 'How to Generate Images Using Python: A Beginner’s Guide',
            text: 'Creating images programmatically can be an exciting venture, whether you\'re looking to automate data visualization, create graphics for your projects, or generate unique art. Python, with its robust libraries and easy-to-learn syntax, is a great tool for this purpose. In this blog post, we’ll walk through the basics of generating images in Python using popular libraries such as Pillow, NumPy, and Matplotlib.',
            link: '/blog/generating_images'
        },
        {
            img: blogImg4,
            title: 'The Future of Video Creation: How AI is Transforming Video Generation',
            text: 'In the age of digital transformation, artificial intelligence (AI) is reshaping numerous industries, and video creation is no exception. AI-powered video generation is rapidly evolving, offering unprecedented opportunities for content creators, marketers, and businesses. This blog explores how AI is revolutionizing video production, its benefits, challenges, and the future of this dynamic technology.',
            link: '/blog/generating_video'
        }
    ]
    
    const currentPageUrl = window.location.href;

    return (
        <div className='blog-bottom'>
            
            <div className='blog-bottom__info-wrap'>
                <div className='blog-bottom__info'>
                    <img 
                        className='blog-bottom__info-img' 
                        src={formImg} 
                        alt='man' 
                    />
                    <div className='blog-bottom__info-title-wrap'>
                        <h4 className='blog-bottom__info-title'>Danylo Nikulshyn</h4>
                        <p className='blog-bottom__info-text'>Full-Stack Developer</p>
                    </div>
                </div>
                <div className='blog-bottom__links--wrap'>
                    <div className='blog-bottom__links-title'>Share:</div>
                    <div className='blog-bottom__links-wrap'>
                        <ul className='blog-bottom__links'>
                            <li className='blog-bottom__link-item'>
                                <a 
                                    className='blog-bottom__link'
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPageUrl)}`}
                                    target='_blank'
                                >
                                    <img 
                                        className='blog-bottom__link-img'
                                        src={facebookImg} 
                                        alt="facebook" 
                                    />
                                </a>
                            </li>
                            <li className='blog-bottom__link-item'>
                                <a 
                                    className='blog-bottom__link'
                                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentPageUrl)}`}
                                    target='_blank'
                                >
                                    <img 
                                        className='blog-bottom__link-img'
                                        src={twitterImg} 
                                        alt="instagram" 
                                    />
                                </a>
                            </li>
                            <li className='blog-bottom__link-item'>
                                <a 
                                    className='blog-bottom__link'
                                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentPageUrl)}`}
                                    target='_blank'
                                >
                                    <img 
                                        className='blog-bottom__link-img'
                                        src={linkedinImg} 
                                        alt="linkedin" 
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <h4 className='blog-bottom__slider-title'>Related blog posts</h4>
            {
                !!dataSwiper?.length &&
                    <Swiper
                        slidesPerView={windowInnerWidth < 640 ? 1.35 : windowInnerWidth > 1024 ? 3 : 2.35}
                        spaceBetween={windowInnerWidth < 640 ? 30 : 40}
                        grabCursor={true}
                        pagination={{
                        clickable: true,
                        }}
                        className="blog-bottom__slider"
                    >
                        {
                            dataSwiper.map((el, index) => (
                                <SwiperSlide key={index}>
                                   <NavLink to={el.link}>
                                        <div className='blog-bottom__slider-item'>
                                            <img 
                                                className='blog-bottom__slider-img'
                                                src={el.img}
                                                alt='slider-img'
                                            />
                                            <div className='blog-bottom__slider-item-title'>{el.title}</div>
                                            <div className='blog-bottom__slider-item-text'>{el.text}</div>
                                        </div>
                                    </NavLink>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
            }
            
        </div>
    );
}

export default memo(BlogBottom);